// vuetify
import { useDisplay } from 'vuetify'

// types
import type { FetchError } from 'ofetch'
import type { ErrorResponse } from '@revolutionprep/types'

// utils
import { formatPhone } from '@revolutionprep/utils'

export function useEnrollmentWizard () {
  /**
   * runtime config
   * ==================================================================
   */
  const config = useRuntimeConfig()

  /**
   * route & router
   * ==================================================================
   */
  const route = useRoute()
  const router = useRouter()

  /**
   * vuetify
   * ==================================================================
   */
  const {
    name: displayBreakpointName,
    smAndUp
  } = useDisplay()

  /**
   * stores
   * ==================================================================
   */
  const parentStore = useParentStore()
  const { parent } = storeToRefs(parentStore)

  const studentStore = useStudentStore()
  const { student } = storeToRefs(studentStore)

  const tutorStore = useTutorStore()
  const { tutor } = storeToRefs(tutorStore)

  const tutorPackageTranscriptStore = useTutorPackageTranscriptStore()
  const { tutorPackageTranscript } = storeToRefs(tutorPackageTranscriptStore)

  /**
   * state
   * ==================================================================
   */
  const error = ref<ErrorResponse | undefined>(undefined)
  const isIntersectingSidebarBottom = ref(false)
  const isIntersectingSidebarTop = ref(false)
  const isProcessing = ref(false)
  const tutorPackageTranscriptIncludes = ref(
    'subject,brand.subjects,parent.address,student.properties,student.school.address,tutor,enrollment.tutors.brand_permissions,enrollment.tutors.subjects,affiliate_reservation.affiliate'
  )

  /**
   * composables
   * ==================================================================
   */
  const { doHandleError } = useErrorHandler()

  /**
   * computed
   * ==================================================================
   */
  const affiliateReservation = computed(() => {
    return tutorPackageTranscript.value?.affiliateReservation || null
  })

  const affiliateReservationNumber = computed(() => {
    return affiliateReservation.value?.reservationNumber || null
  })

  const brandId = computed(() => {
    return tutorPackageTranscriptBrand.value?.id || null
  })

  const companyPhone = computed(() => {
    return affiliateReservation.value?.affiliate?.phoneCustomerService
      ? formatPhone(
        affiliateReservation.value?.affiliate?.phoneCustomerService
      )
      : formatPhone(config.public.companyPhone)
  })

  const isParentValid = computed(() => {
    if (!parent.value) {
      return false
    }

    if (
      parent.value.firstName &&
      parent.value.lastName &&
      parent.value.phone &&
      parent.value.smsOptInType &&
      parent.value.email &&
      parent.value.address &&
      parent.value.timeZone
    ) {
      return true
    }

    return false
  })

  const isStudentValid = computed(() => {
    if (!student.value) {
      return false
    }

    const isStudentGradeAtLeast6 = Number(student.value?.grade) >= 6

    if (
      student.value.firstName &&
      student.value.lastName &&
      typeof student.value.grade === 'number' &&
      student.value.notesPersonal &&
      student.value.notesGoals &&
      student.value.hobbies &&
      student.value.school &&
      student.value.timeZone
    ) {
      if (isStudentGradeAtLeast6) {
        return Boolean(
          student.value.email
        )
      }
      return true
    }

    return false
  })

  const lastStepCompleted = computed(() => {
    let lastStepComplete = 0
    if (isParentValid.value && isStudentValid.value) {
      lastStepComplete = 1
    }
    if (
      tutorPackageTranscript.value?.subject?.id &&
      tutorPackageTranscript.value?.subjectDuration &&
      tutorPackageTranscript.value?.subjectFrequency &&
      lastStepComplete === 1
    ) {
      lastStepComplete = 2
    }
    if (
      student.value?.availabilitiesJson.length &&
      lastStepComplete === 2
    ) {
      lastStepComplete = 3
    }
    if (
      tutorPackageTranscript.value?.tutor &&
      lastStepComplete === 3
    ) {
      lastStepComplete = 4
    }
    if (
      affiliateReservation.value?.status === 'active' &&
      lastStepComplete === 4
    ) {
      lastStepComplete = 5
    }
    return lastStepComplete
  })

  const sidebarCssClasses = computed(() => {
    return smAndUp.value && !isIntersectingSidebarTop.value
      ? 'sticky-sidebar pt-12'
      : ''
  })

  const step1Url = computed(() => {
    return stepRouteMap.value[1]
  })

  const stepRouteMap = computed(() => {
    return {
      1: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }`,
      2: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }/subject`,
      3: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }/availability`,
      4: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }/tutor`,
      5: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }/submit`,
      6: `/bright-horizons/enrollment-wizard/${
        tutorPackageTranscriptId.value
      }/complete`
    } as { [key: number]: string }
  })

  const studentAvailabilitiesJson = computed(() => {
    return student.value?.availabilitiesJson || []
  })

  const studentFirstName = computed(() => {
    return student.value?.firstName || ''
  })

  const studentFullName = computed(() => {
    return student.value?.fullName || ''
  })

  const subjectId = computed(() => {
    return tutorPackageTranscriptSubject.value?.id || null
  })

  const tutorPackageTranscriptBrand = computed(() => {
    return tutorPackageTranscript.value?.brand || null
  })

  const tutorPackageTranscriptBrandSubjects = computed(() => {
    return tutorPackageTranscript.value?.brand?.subjects || []
  })

  const tutorPackageTranscriptId = computed(() => {
    return Number(route.params.tutorPackageTranscriptId)
  })

  const tutorPackageTranscriptSubject = computed(() => {
    return tutorPackageTranscript.value?.subject || null
  })

  /**
   * methods
   * ==================================================================
   */
  async function fetchTutorPackageTranscript (
    tutorPackageTranscriptId: number
  ) {
    try {
      const _tutorPackageTranscript = await tutorPackageTranscriptStore.show(
        tutorPackageTranscriptId,
        {
          params: {
            include: tutorPackageTranscriptIncludes.value
          }
        }
      )
      if (_tutorPackageTranscript.value?.parent) {
        parent.value = _tutorPackageTranscript.value.parent
      }
      if (_tutorPackageTranscript.value?.student) {
        student.value = _tutorPackageTranscript.value.student
      }
      if (_tutorPackageTranscript.value?.tutor) {
        tutor.value = _tutorPackageTranscript.value.tutor
      }
    } catch (error) {
      doHandleError(error as FetchError)
    }
  }

  function onIntersectSidebarBottom (
    _isIntersecting: boolean,
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver
  ) {
    isIntersectingSidebarBottom.value = entries[0].isIntersecting
  }

  function onIntersectSidebarTop (
    _isIntersecting: boolean,
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver
  ) {
    isIntersectingSidebarTop.value = entries[0].isIntersecting
  }

  function toolbarCssClasses (_breakpoint = 'xs') {
    return (
      _breakpoint === displayBreakpointName.value &&
      !isIntersectingSidebarBottom.value
    )
      ? 'fixed-toolbar'
      : ''
  }

  function toNextStep (currentStep: number) {
    if (currentStep <= lastStepCompleted.value) {
      router.push({
        path: stepRouteMap.value[currentStep + 1]
      })
    }
  }

  function toPreviousStep (currentStep: number) {
    router.push({
      path: stepRouteMap.value[currentStep - 1]
    })
  }

  return {
    affiliateReservation,
    affiliateReservationNumber,
    brandId,
    companyPhone,
    error,
    fetchTutorPackageTranscript,
    isIntersectingSidebarBottom,
    isIntersectingSidebarTop,
    isParentValid,
    isProcessing,
    isStudentValid,
    lastStepCompleted,
    onIntersectSidebarBottom,
    onIntersectSidebarTop,
    parent,
    sidebarCssClasses,
    step1Url,
    stepRouteMap,
    student,
    studentAvailabilitiesJson,
    studentFirstName,
    studentFullName,
    subjectId,
    toNextStep,
    toPreviousStep,
    toolbarCssClasses,
    tutor,
    tutorPackageTranscript,
    tutorPackageTranscriptBrand,
    tutorPackageTranscriptBrandSubjects,
    tutorPackageTranscriptId,
    tutorPackageTranscriptIncludes,
    tutorPackageTranscriptSubject
  }
}
